<template>
	<!-- <div class="read_articles"> -->
	<router-link v-if="msg.jump == 'content'" :to="'/details/' + tzfs + '/' + msg.id + '?type=' + types"
		class="read_articles_a js-parallax scrolled index_feature_ul_li_a">

		<div class="index_feature_ul_li_a_imgwrap">
			<ScaleDiv :scale="188 / 281" w="100%" :img="GS(msg.thumb)">
			</ScaleDiv>
		</div>
		<Row type="flex" justify="center" align="middle" style="margin-top:12px;">
			<i-col span="12">
				<p class="read_articles_a_tag" style="margin-top:0px;line-height:1.5;">{{ msg.catname }}</p>
			</i-col>
			<i-col span="12">
				<p class="read_articles_a_description" style="margin-top:0px;text-align:right;margin-right:5px;">
					{{ msg.timeline }}</p>
			</i-col>
		</Row>
		<h4 class="bold" style="margin-top:5px;">{{ msg.title }}</h4>
		<p class="read_articles_a_btn" style="margin-top:20px;"><span>查看详情</span></p>
	</router-link>
	<a :href="msg.url" v-else class="read_articles_a js-parallax scrolled index_feature_ul_li_a">
		<div class="index_feature_ul_li_a_imgwrap">
			<ScaleDiv :scale="188 / 281" w="100%" :img="GS(msg.thumb)">
			</ScaleDiv>
		</div>
		<Row type="flex" justify="center" align="middle" style="margin-top:12px;">
			<i-col span="12">
				<p class="read_articles_a_tag" style="margin-top:0px;line-height:1.5;">{{ msg.catname }}</p>
			</i-col>
			<i-col span="12">
				<p class="read_articles_a_description" style="margin-top:0px;text-align:right;margin-right:5px;">
					{{ msg.timeline }}</p>
			</i-col>
		</Row>
		<h4 class="bold" style="margin-top:5px;">{{ msg.title }}</h4>
		<p class="read_articles_a_btn" style="margin-top:20px;"><span>查看详情</span></p>
	</a>
	<!-- {{msg}} -->
	<!-- </div> -->
</template>

<script>
import ScaleDiv from './ScaleDiv.vue'
export default {
	components: {
		ScaleDiv
	},
	name: 'list',
	props: {
		'msg': {
			type: Object,
			default() {
				return [];
			}
		},
		'types': {
			type: String,
			default: ""
		},
		'tzfs': {
			type: String,
			default: "text"
		}
	},
	created() {
		console.log(this.types, 12)
	}
}
</script>

<style></style>
<script>
var _hmt = _hmt || [];
(function () {
	var hm = document.createElement('script');
	hm.src = 'https://hm.baidu.com/hm.js?819d608e55a54ab96615bb5d06bb6230';
	var s = document.getElementsByTagName('script')[0];
	s.parentNode.insertBefore(hm, s);
})();
</script>