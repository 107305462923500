<template>
	<div>
		<!-- <img src="../../assets/img/xintp/sybj.png" alt=""> -->
		<img src="../../assets/img/xintp/banner.jpg" alt="" style="width: 100%; height: auto" />
		<div class="ztnrbg">
			<div class="index_mv ztnr">
				<leftMeun></leftMeun>
				<div class="right_nr">
					<div class="category_news_article" style="border: 0; margin-bottom: 0"><rightMeun></rightMeun></div>
					<p style="text-align: right">
						字号：<a @click="SetFont(16)">大</a> <a @click="SetFont(14)">中</a> <a @click="SetFont(12)">小</a>
					</p>
					<article class="category_news_article" style="border: 0; margin-bottom: 0; margin-top: 50px">
						<div class="newsTit">
							{{ data.title }}
							<p>发表于：{{ data.timeline }} 浏览次数：{{ data.browse }}次</p>
						</div>
						<section class="notice_article js-parallax scrolled">
							<!-- <div class="notice_article_head">
								<Breadcrumb v-if="href1">
									<BreadcrumbItem :to="href1">{{data.fname}}</BreadcrumbItem>
									<BreadcrumbItem :to="href2">{{data.catname}}</BreadcrumbItem>
								</Breadcrumb>
								<h2 style="font-weight:bold;font-size:22px;line-height:1.6;margin-top:3px; margin-bottom:-10px;">
									{{data.title}}</h2>
								<span class="notice_article_head_time" style="margin-top:-10px;">{{data.timeline}}</span>
							</div> -->
							<div class="notice_article_body">
								<div
									id="body_data"
									class="notice_article_body_text"
									:style="{ fontSize: '16px!important' }"
									v-html="data.content"
								></div>
								<div class="filesL">
									<div v-for="(li, index) in data.files" :key="index">
										<div>{{ li.name }}</div>
										<a :href="li.path">点击下载</a>
									</div>
								</div>
							</div>
						</section>
						<div class="read-s_pagenation">
							<a class="read-s_pagenation_a js-parallax scrolled" href="#"><span>返回顶部</span></a>
						</div>
					</article>
					<!-- <div class="notice_banners" style="padding-top:0px;padding-bottom:51px;">
						<div class="list">
							<div class="read_articles" style="border:0">
								<Row type="flex" justify="start" align="top" style="width:100%;">
									<i-col :xs="24" :sm="12" :lg="8" v-for="(li,index) in tuij" :key='index' class-name="flexcenter">
										<list :msg="li" />
									</i-col>
								</Row>
							</div>
						</div>
					</div> -->
				</div>
				<newRightMeun2></newRightMeun2>
			</div>
		</div>
	</div>
</template>
<script>
var _hmt = _hmt || [];
(function () {
	var hm = document.createElement('script');
	hm.src = 'https://hm.baidu.com/hm.js?819d608e55a54ab96615bb5d06bb6230';
	var s = document.getElementsByTagName('script')[0];
	s.parentNode.insertBefore(hm, s);
})();
</script>
<script>
import list from '@/components/list.vue';
import leftMeun from '@/components/leftMeun.vue';
import rightMeun from '@/components/rightMeun.vue';
import newRightMeun2 from '@/components/newRightMeun2.vue';
import $ from 'jquery';
export default {
	components: {
		list,
		leftMeun,
		rightMeun,
		newRightMeun2,
	},
	data() {
		return {
			data: '',
			tuij: [],
			href1: '',
			href2: '',
		};
	},
	watch: {
		$route: 'types',
	},
	methods: {
		types() {
			var that = this;
			that.$api
				.detai({
					id: that.$route.params.newsid,
				})
				.then((res) => {
					that.$emit('getLoad', true);
					if (res.code == 1) {
						that.data = res.data;
								let _setFont = ms =>{
									ms+=100;
									setTimeout(() => {
										this.SetFont(14);
										if(ms<600){
											_setFont();
										}
									}, ms);
								}
								_setFont();
					
					} else {
						that.data = '暂无介绍';
					}
					console.log(that.data);
				})
				.catch((error) => {
					// error
					console.log(error);
				});
			// that.$api.tuijan({
			// 	id: that.$route.params.newsid
			// }).then(res => {
			// 	// success
			// 	that.tuij = res.data
			// 	console.log(res)
			// }).catch((error) => {
			// 	// error
			// 	console.log(error)
			// })
		},
		SetFont(size) {
						$('#body_data *').css({fontSize: size + 'px',fontFamily:'宋体',lineHeight:2});
		},
	},
	mounted() {
		var that = this;
		that.types();
	},
};
</script>

<style>
.notice_article {
	margin-top: 40px;
}

.mbx {
	margin-top: 20px;
	margin-bottom: 0;
	font-size: 15px;
}

.filesL div a {
	margin: 0 0 0 20px;
}

.filesL div div {
	flex: 1;
}

.filesL > div {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;
	border-bottom: 1px solid #eee;
}
</style>
